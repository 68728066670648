import React from "react";
import _ from "lodash";

import { Layout } from "../components/index";
import { markdownify, Link } from "../utils";

/* eslint-disable */

export default class Elements extends React.Component {
  render() {
    return (
      <Layout {...this.props}>
        <article id="main">
          <header>
            <h2>{_.get(this.props, "pageContext.frontmatter.title")}</h2>
            {markdownify(_.get(this.props, "pageContext.frontmatter.subtitle"))}
            <section>
              <Link to="tel:+61242560559" className="button primary">
                CALL US NOW
              </Link>
            </section>
          </header>
          <section className="wrapper style5">
            <div className="inner">
              <section>
                <h4>ABN</h4>
                <p>90 613 361 756 </p>

                <h4>NSW Builders Licence</h4>
                <p>198835C</p>

                <h4>Postal Address</h4>
                <p>
                  PO Box 231
                  <br />
                  Albion Park NSW 2527
                </p>

                <h4>Phone</h4>
                <p>
                  <a href="tel:+61242560559">(02) 4256 0559</a>
                </p>

                <h4>Fax</h4>
                <p>(02) 4257 9655</p>

                <div className="inner">
                  <h4>Our details</h4>
                  <div className="table-wrapper">
                    <table className="alt">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Michael Hutchison</td>
                          <td>
                            <a href="mailto:mick@hutchos.com.au">
                              mick@hutchos.com.au
                            </a>
                          </td>
                          <td>
                            <a href="tel:+61414568559">
                              0414&nbsp;568&nbsp;559
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Greg Simpson</td>
                          <td>
                            <a href="mailto:greg@hutchos.com.au">
                              greg@hutchos.com.au
                            </a>
                          </td>
                          <td>
                            <a href="tel:+61406316266">
                              0406&nbsp;316&nbsp;266
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Jordan Hutchison</td>
                          <td>
                            <a href="jomailto:rdan@hutchos.com.au">
                              jordan@hutchos.com.au
                            </a>
                          </td>
                          <td>
                            <a href="tel:+61426176065">
                              0426&nbsp;176&nbsp;065
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Accounts</td>
                          <td>
                            <a href="lmailto:ynne@hutchos.com.au">
                              lynne@hutchos.com.au
                            </a>
                          </td>
                          <td>
                            <a href="tel:+61242560559">
                              (02)&nbsp;4256&nbsp;0559
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </article>
      </Layout>
    );
  }
}
